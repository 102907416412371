import PropTypes from 'prop-types';
import {
  TimeIcon,
  LaptopIcon,
  SalaryIcon,
  PlaneIcon,
  PersonIcon,
  TrainingIcon,
  OnsiteIcon,
  EventIcon,
  CoffeeIcon,
} from '../Icons';
import { colors } from '../Theme/Colors';

export const BenefitIcons = ({ name, className }) => {
  let icon;

  switch (name) {
    case 'time':
      icon = <TimeIcon className={className} color={colors.textNavColor} />;
      break;
    case 'laptop':
      icon = <LaptopIcon className={className} color={colors.textNavColor} />;
      break;
    case 'salary':
      icon = <SalaryIcon className={className} color={colors.textNavColor} />;
      break;
    case 'plane':
      icon = <PlaneIcon className={className} color={colors.textNavColor} />;
      break;
    case 'person':
      icon = <PersonIcon className={className} color={colors.textNavColor} />;
      break;
    case 'training':
      icon = <TrainingIcon className={className} color={colors.textNavColor} />;
      break;
    case 'onsite':
      icon = <OnsiteIcon className={className} color={colors.textNavColor} />;
      break;
    case 'event':
      icon = <EventIcon className={className} color={colors.textNavColor} />;
      break;
    case 'coffee':
      icon = <CoffeeIcon className={className} color={colors.textNavColor} />;
      break;

    default:
      icon = <SalaryIcon className={className} color={colors.textNavColor} />;
      break;
  }

  return icon;
};

BenefitIcons.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
