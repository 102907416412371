import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { ArrowRight } from '../../components/Shape/ArrowRight';
import { GradientText } from '../Text';
import { H3 } from '../Text/H3';
import { H6 } from '../Text/H6';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';

const JobPreviewWrapper = styled.div`
  .job-preview__title {
    position: relative;
    h3 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      white-space: break-spaces;
    }
    span {
      padding-left: 12rem;
      font-size: 16rem;
      position: relative;
      z-index: -1;
      top: 1rem;
    }
  }

  .job-preview__opening-postions {
    list-style-type: none;
    font-weight: 500;

    a {
      text-decoration: none;
      color: ${colors.textColor};
      display: inline-block;
      h6 {
        display: inline-block;
      }
      .arrow-right-icon {
        opacity: 0;
        transition: all 0.5s ease;
        transform: translateX(0.5rem);
      }
      &:hover {
        .arrow-right-icon {
          opacity: 1;
          transform: translateX(1.6rem);
        }
      }
      &:active,
      &:focus {
        color: ${colors.eerie800};
        .arrow-right-icon {
          opacity: 0;
        }
      }
    }

    li:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }

  @media (prefers-color-scheme: dark) {
    .job-preview__title {
      span {
        color: ${colors.backgroundLight};
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: ${colors.textColor};
      }
    }
  }

  ${media.mobileWide`
    .job-preview__title {
      h3 { 
        font-size: 3.2rem;
        line-height: 4.736rem;
        letter-spacing: 4%;
      }
      span {
        padding-left: 10rem;
        font-size: 10rem;
        top: -3rem;
      }
    }
    .job-preview__opening-postions {
      li:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      a {
        .arrow-right-icon {
          display: none;
        }
      }
    }
  `}
`;

export const JobPreview = ({ title, jobs }) => {
  const handleLinkTo = (event, jobId) => {
    event.preventDefault();
    navigate(`/career-details/?id=${jobId}`);
  };

  return (
    <JobPreviewWrapper>
      <div className="job-preview__title">
        <H3 data-scroll-speed="1" data-scroll>
          {title}
        </H3>
        {jobs.length !== 0 && (
          <GradientText color="white" textShadow={colors.nightRider}>
            {jobs.length}
          </GradientText>
        )}
      </div>
      <ul className="job-preview__opening-postions">
        {jobs.map(job => (
          <li key={job.name} data-scroll-speed="1" data-scroll>
            <a className="hoverable" onClick={e => handleLinkTo(e, job.id)}>
              <H6>{job.name}</H6>
              <ArrowRight
                className="arrow-right-icon"
                color={colors.textColor}
              />
            </a>
          </li>
        ))}
      </ul>
    </JobPreviewWrapper>
  );
};

JobPreview.propTypes = {
  title: PropTypes.string.isRequired,
  jobs: PropTypes.array,
};

JobPreview.defaultProps = {
  jobs: [],
};
