import { useState } from 'react';
import styled from '@emotion/styled';
import { PolygonIcon } from '../../components/Shape/PolygonIcon';
import { colors } from '../../components/Theme/Colors';
import { media, sizes } from '../../components/Theme/Media';
import { resetButton } from '../../components/Theme/Mixins';
import { Body1, Body2 } from '../../components/Theme/Typography';
import { VALUES_COMPANY, isDesktopScreen } from '../../utils/constants';
import { Benefits } from './Benefits';
import { Culture } from './Culture';
import { CultureSlider } from './CultureSlider';
import { Values } from './Values';
import polygon_yellow from '../../../static/images/polygon_yellow.svg';
import polygon_gray from '../../../static/images/polygon_gray.svg';

const ValuesWrapper = styled.section`
  padding: 0 0 1rem;
  background-color: ${colors.eerie};
  min-height: 100vh;

  .values__container {
    position: relative;
    .values__content {
      max-width: 114.5rem;
      margin: 0 auto;
      display: flex;

      .content__title,
      .content__desciption {
        flex: 1 1 50%;
        color: ${colors.textNavColor};
      }

      .content__desciption {
        padding-top: 18rem;
        p {
          line-height: 2.4rem !important;
          ${Body1};
          /* max-width: 49rem; */
          font-weight: 400;
          letter-spacing: 0.167rem;
          margin-bottom: 4rem;
        }

        .core-values {
          max-width: 46rem;
        }
      }

      .content__title {
        margin-right: 3rem;
        ul {
          list-style-type: none;

          li:not(:last-child) {
            /* margin-bottom: 2.4rem; */
          }

          button {
            display: flex;
            align-items: center;

            color: ${colors.eerie500};
            ${resetButton};
            margin-bottom: 2.4rem;
            /* transition: all 0.5s; */

            font-family: Ubuntu, sans-serif;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.2rem;
            letter-spacing: 0.04em;

            &.active {
              padding-top: 10rem;
              padding-bottom: 4rem;
              font-weight: 400;

              span {
                color: ${colors.textNavColor};
                transform: scale(2.9);
                transform-origin: left center;
                letter-spacing: 0.1rem;
              }

              figure {
                display: inline-flex;
              }
            }

            figure {
              margin-right: 2.5rem;
              display: none;
            }

            &:not(.active) {
              span {
                transition: all 0.5s;
              }

              i {
                width: 1rem;
                height: 1.02rem;
                background-image: url('${polygon_yellow}');
                background-repeat: no-repeat;
                display: none;
                background-size: contain;
                transition: all 0.5s;
              }

              &:hover {
                span {
                  background-image: ${colors.gradientOrange};
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  transform: translateX(1rem);
                }
                i {
                  display: inline-flex;
                }
              }

              &:active {
                span {
                  color: ${colors.eerie500};
                  background-image: none;
                  -webkit-text-fill-color: initial;
                  -webkit-background-clip: initial;
                  transform: translateX(1rem);
                }
                i {
                  background-image: url('${polygon_gray}');
                }
              }
            }
          }
        }
      }

      .content__desciption {
        p {
          ${Body2};
        }
      }
    }
    aside {
      /* bottom: -10rem; */
    }
  }

  @media (min-width: ${sizes.mobileWide}px) and (max-width: ${sizes.desktopGiant}px) {
    height: 100vh;
  }

  @media (min-width: ${sizes.desktopGiant}px) {
    padding: 0 0 11rem;
    height: unset;
  }

  ${media.mobileWide`
    height: 70rem;
    padding: 4.8rem 0 0;

    .values__container {
      .values__content {
      padding: 0 1.6rem;
      margin-bottom: 3.2rem;
      display: block;

      .content__title {
        ul {
          button {
            &.active {
              padding-bottom: 1rem;
              padding-top: 3rem;
              span {
                transform: scale(2);
              }
            }
            figure {
              display: none !important;
            }
          }
        }
      }

      .content__desciption {
        padding-top: 2rem;
        display: flex;
        overflow-x: scroll;

        .core-values {
          max-width: unset;
          display: flex;
          overflow-x: scroll;
          padding-bottom: 2rem;
        }

        p {
          max-width: 100%;
          min-width: 21.2rem;
          font-size: 1.6rem;
          line-height: 2.2rem !important;
          letter-spacing: 0.04em;
          margin-bottom: 0;
        }

        p:not(:last-child){
          margin-right: 4.8rem;
        }
      }
    }
    aside {
      bottom: unset;
    }
    }
  `}
`;

let values = [
  { label: VALUES_COMPANY.OUR_CULTURE, value: 'culture' },
  { label: VALUES_COMPANY.BENEFITS, value: 'benefits' },
  { label: VALUES_COMPANY.OUR_STORY, value: 'value' },
];

export const Introduction = () => {
  const [currentTitle, setCurrentTitle] = useState(VALUES_COMPANY.OUR_CULTURE);

  const renderDescription = title => {
    switch (title) {
      case VALUES_COMPANY.OUR_CULTURE:
        return Culture;
      case VALUES_COMPANY.BENEFITS:
        return Benefits;
      default:
        return Values;
    }
  };

  const handleSelectValue = value => {
    setCurrentTitle(value);
    values.forEach(function (item, i) {
      if (item.label === value) {
        values.splice(i, 1);
        values.unshift(item);
      }
    });
    if(isDesktopScreen) {
      setTimeout(() => {
        window.scroll.update();
      }, 1000);
    }
  };

  return (
    <ValuesWrapper data-scroll>
      <div className="values__container">
        <div className="values__content">
          <div className="content__title">
            <ul>
              {values.map((item, index) => (
                <li key={index}>
                  <button
                    className={`hoverable ${
                      currentTitle === item.label ? 'active' : ''
                    }`}
                    type="button"
                    onClick={() => handleSelectValue(item.label)}
                  >
                    <PolygonIcon color={colors.cultured} />
                    {currentTitle !== item.label && <i />}
                    <span>{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="content__desciption">
            {renderDescription(currentTitle)}
          </div>
        </div>
        <CultureSlider display={currentTitle === VALUES_COMPANY.OUR_CULTURE} />
      </div>
    </ValuesWrapper>
  );
};

Introduction.propTypes = {};
