/* eslint-disable react/no-unknown-property */
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const EventIcon = ({ color = '#EEF0F2', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.02671 8.03569C8.47645 7.92952 8.9491 8.06379 9.27585 8.39055L22.6092 21.7239C22.9359 22.0506 23.0702 22.5233 22.964 22.973C22.8579 23.4228 22.5264 23.7855 22.088 23.9316L2.08801 30.5983C1.6089 30.758 1.08068 30.6333 0.723568 30.2762C0.366459 29.9191 0.241762 29.3908 0.401466 28.9117L7.06813 8.91172C7.21426 8.47333 7.57697 8.14186 8.02671 8.03569ZM8.91573 11.8017L3.77456 27.2252L19.1981 22.084L8.91573 11.8017Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0877 1.40179C14.7863 1.63466 15.1638 2.38975 14.931 3.08834L13.5976 7.08834C13.3648 7.78693 12.6097 8.16448 11.9111 7.93161C11.2125 7.69875 10.8349 6.94366 11.0678 6.24507L12.4011 2.24507C12.634 1.54647 13.3891 1.16893 14.0877 1.40179Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5976 16.9118C29.8305 17.6104 29.4529 18.3655 28.7544 18.5984L24.7544 19.9317C24.0558 20.1646 23.3007 19.787 23.0678 19.0884C22.8349 18.3898 23.2125 17.6347 23.9111 17.4019L27.9111 16.0685C28.6097 15.8357 29.3648 16.2132 29.5976 16.9118Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1763 2.76816C21.9326 2.66725 21.6645 2.64084 21.4059 2.69229C21.1473 2.74374 20.9097 2.87072 20.7232 3.05719C20.5367 3.24366 20.4098 3.48124 20.3583 3.73988C20.3069 3.99852 20.3333 4.26661 20.4342 4.51025C20.5351 4.75388 20.706 4.96212 20.9253 5.10863C21.1445 5.25514 21.4023 5.33334 21.666 5.33334C22.0196 5.33334 22.3588 5.19286 22.6088 4.94281C22.8589 4.69276 22.9994 4.35362 22.9994 4C22.9994 3.73629 22.9212 3.47851 22.7746 3.25924C22.6281 3.03998 22.4199 2.86908 22.1763 2.76816ZM20.8857 0.0768607C21.6616 -0.0774802 22.4658 0.00173304 23.1968 0.304484C23.9277 0.607235 24.5524 1.11993 24.9919 1.77772C25.4314 2.43552 25.666 3.20888 25.666 4C25.666 5.06087 25.2446 6.07828 24.4944 6.82843C23.7443 7.57857 22.7269 8 21.666 8C20.8749 8 20.1015 7.76541 19.4437 7.32588C18.7859 6.88636 18.2733 6.26164 17.9705 5.53074C17.6677 4.79983 17.5885 3.99556 17.7429 3.21964C17.8972 2.44372 18.2782 1.73098 18.8376 1.17157C19.397 0.612165 20.1097 0.231202 20.8857 0.0768607Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6663 2.66675C30.4027 2.66675 30.9997 3.2637 30.9997 4.00008C30.9997 6.47543 30.0163 8.8494 28.266 10.5997C26.5157 12.3501 24.1417 13.3334 21.6663 13.3334C20.93 13.3334 20.333 12.7365 20.333 12.0001C20.333 11.2637 20.93 10.6667 21.6663 10.6667C23.4345 10.6667 25.1301 9.96437 26.3804 8.71413C27.6306 7.46388 28.333 5.76819 28.333 4.00008C28.333 3.2637 28.93 2.66675 29.6663 2.66675Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92763 17.0205C5.62621 16.7876 6.38132 17.1651 6.61421 17.8637L9.56088 26.7023C9.79377 27.4009 9.41626 28.156 8.71768 28.3889C8.0191 28.6218 7.26399 28.2443 7.0311 27.5457L4.08443 18.707C3.85153 18.0085 4.22905 17.2534 4.92763 17.0205Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.959 9.11637C9.65761 8.88354 10.4127 9.26113 10.6455 9.95973L15.5588 24.7024C15.7917 25.401 15.4141 26.1561 14.7155 26.3889C14.0169 26.6217 13.2618 26.2441 13.029 25.5455L8.11564 10.8029C7.88281 10.1043 8.2604 9.34919 8.959 9.11637Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

EventIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
