import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const TimeIcon = ({ color = '#EEF0F2', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6663 20C23.4027 20 23.9997 20.597 23.9997 21.3333V22.6667H25.333C26.0694 22.6667 26.6663 23.2636 26.6663 24C26.6663 24.7364 26.0694 25.3333 25.333 25.3333H22.6663C21.93 25.3333 21.333 24.7364 21.333 24V21.3333C21.333 20.597 21.93 20 22.6663 20Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.667 18.6667C19.7215 18.6667 17.3337 21.0545 17.3337 24C17.3337 26.9455 19.7215 29.3333 22.667 29.3333C25.6125 29.3333 28.0003 26.9455 28.0003 24C28.0003 21.0545 25.6125 18.6667 22.667 18.6667ZM14.667 24C14.667 19.5817 18.2487 16 22.667 16C27.0853 16 30.667 19.5817 30.667 24C30.667 28.4183 27.0853 32 22.667 32C18.2487 32 14.667 28.4183 14.667 24Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.33333C0 4.59695 0.596954 4 1.33333 4H30.6667C31.403 4 32 4.59695 32 5.33333V14.6667C32 15.403 31.403 16 30.6667 16C29.9303 16 29.3333 15.403 29.3333 14.6667V6.66667H2.66667V28H12C12.7364 28 13.3333 28.597 13.3333 29.3333C13.3333 30.0697 12.7364 30.6667 12 30.6667H1.33333C0.596954 30.6667 0 30.0697 0 29.3333V5.33333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33333 0C10.0697 0 10.6667 0.596954 10.6667 1.33333V5.33333C10.6667 6.06971 10.0697 6.66667 9.33333 6.66667C8.59695 6.66667 8 6.06971 8 5.33333V1.33333C8 0.596954 8.59695 0 9.33333 0Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6663 0C23.4027 0 23.9997 0.596954 23.9997 1.33333V5.33333C23.9997 6.06971 23.4027 6.66667 22.6663 6.66667C21.93 6.66667 21.333 6.06971 21.333 5.33333V1.33333C21.333 0.596954 21.93 0 22.6663 0Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.6666C0 9.93021 0.596954 9.33325 1.33333 9.33325H30.6667C31.403 9.33325 32 9.93021 32 10.6666C32 11.403 31.403 11.9999 30.6667 11.9999H1.33333C0.596954 11.9999 0 11.403 0 10.6666Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

TimeIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
