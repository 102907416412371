/* eslint-disable react/no-unknown-property */
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const CoffeeIcon = ({ color = '#EEF0F2', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 0C14.0697 0 14.6667 0.596954 14.6667 1.33333V4C14.6667 4.73638 14.0697 5.33333 13.3333 5.33333C12.597 5.33333 12 4.73638 12 4V1.33333C12 0.596954 12.597 0 13.3333 0Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66634 1.33325C7.40272 1.33325 7.99967 1.93021 7.99967 2.66659V5.33325C7.99967 6.06963 7.40272 6.66658 6.66634 6.66658C5.92996 6.66658 5.33301 6.06963 5.33301 5.33325V2.66659C5.33301 1.93021 5.92996 1.33325 6.66634 1.33325Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0003 1.33325C20.7367 1.33325 21.3337 1.93021 21.3337 2.66659V5.33325C21.3337 6.06963 20.7367 6.66658 20.0003 6.66658C19.2639 6.66658 18.667 6.06963 18.667 5.33325V2.66659C18.667 1.93021 19.2639 1.33325 20.0003 1.33325Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9997 10.6666C23.9997 9.93021 24.5966 9.33325 25.333 9.33325H30.6663C31.4027 9.33325 31.9997 9.93021 31.9997 10.6666V15.9999C31.9997 18.203 30.2027 19.9999 27.9997 19.9999H24.6663C23.93 19.9999 23.333 19.403 23.333 18.6666C23.333 17.9302 23.93 17.3333 24.6663 17.3333H27.9997C28.73 17.3333 29.333 16.7302 29.333 15.9999V11.9999H25.333C24.5966 11.9999 23.9997 11.403 23.9997 10.6666Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.6666C0 9.93021 0.596954 9.33325 1.33333 9.33325H25.3333C26.0697 9.33325 26.6667 9.93021 26.6667 10.6666V14.6666C26.6667 22.0696 20.7364 27.9999 13.3333 27.9999C5.93029 27.9999 0 22.0696 0 14.6666V10.6666ZM2.66667 11.9999V14.6666C2.66667 20.5969 7.40305 25.3333 13.3333 25.3333C19.2636 25.3333 24 20.5969 24 14.6666V11.9999H2.66667Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 30.6666C0 29.9302 0.596954 29.3333 1.33333 29.3333H25.3333C26.0697 29.3333 26.6667 29.9302 26.6667 30.6666C26.6667 31.403 26.0697 31.9999 25.3333 31.9999H1.33333C0.596954 31.9999 0 31.403 0 30.6666Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

CoffeeIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
