import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const PersonIcon = ({ color = '#EEF0F2', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.94033 17.6606C6.22001 16.8919 9.70974 16 13.6663 16C14.4027 16 14.9997 16.597 14.9997 17.3333C14.9997 18.0697 14.4027 18.6667 13.6663 18.6667C10.0926 18.6667 6.90263 19.476 4.79297 20.1872C3.72282 20.5495 2.99967 21.5622 2.99967 22.7093V26.6667H12.333C13.0694 26.6667 13.6663 27.2636 13.6663 28C13.6663 28.7364 13.0694 29.3333 12.333 29.3333H1.66634C0.929961 29.3333 0.333008 28.7364 0.333008 28V22.7093C0.333008 20.4354 1.76958 18.3949 3.9391 17.661L3.94033 17.6606Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.666 2.66667C10.7211 2.66667 8.33268 5.05505 8.33268 8V9.33333C8.33268 10.8424 8.95731 12.5585 10.0017 13.8915C11.0501 15.2296 12.3674 16 13.666 16C14.9647 16 16.2819 15.2296 17.3303 13.8915C18.3747 12.5585 18.9993 10.8424 18.9993 9.33333V8C18.9993 5.05505 16.611 2.66667 13.666 2.66667ZM5.66602 8C5.66602 3.58229 9.2483 0 13.666 0C18.0837 0 21.666 3.58229 21.666 8V9.33333C21.666 11.5056 20.798 13.7895 19.4294 15.5362C18.0648 17.2778 16.0487 18.6667 13.666 18.6667C11.2833 18.6667 9.26722 17.2778 7.90264 15.5362C6.53405 13.7895 5.66602 11.5056 5.66602 9.33333V8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2755 21.7238C29.7962 22.2445 29.7962 23.0887 29.2755 23.6094L22.6088 30.2761C22.0881 30.7968 21.2439 30.7968 20.7232 30.2761L18.0565 27.6094C17.5358 27.0887 17.5358 26.2445 18.0565 25.7238C18.5772 25.2031 19.4215 25.2031 19.9422 25.7238L21.666 27.4476L27.3899 21.7238C27.9106 21.2031 28.7548 21.2031 29.2755 21.7238Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

PersonIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
