import { useStaticQuery, graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { H3 } from '../components/Text/H3';
import Img from '../components/image';
import {
  Introduction,
  OpeningPositions,
  Welcome,
} from '../containers/CareerPage';

const titleStyle = { padding: '0 1rem 6rem' };

const Career = ({ location }) => {
  const introImg = useStaticQuery(graphql`
    query {
      careerIntro: file(relativePath: { eq: "career_intro.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout location={location} nextPage="Homepage">
      <Welcome>
        <H3 data-scroll-speed="1" data-scroll style={titleStyle}>
          We don’t merely build software, we build happy and excellent IT
          leaders.
        </H3>
        <Img alt="lecle career" imgData={introImg} name="careerIntro" />
      </Welcome>
      <Introduction />
      <OpeningPositions />
    </Layout>
  );
};

export default Career;
