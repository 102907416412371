import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const TrainingIcon = ({ color = '#EEF0F2', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33333 9.33325C10.0697 9.33325 10.6667 9.93021 10.6667 10.6666V30.6666C10.6667 31.403 10.0697 31.9999 9.33333 31.9999C8.59695 31.9999 8 31.403 8 30.6666V10.6666C8 9.93021 8.59695 9.33325 9.33333 9.33325Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33301 2.66667C4.59663 2.66667 3.99967 3.26362 3.99967 4C3.99967 4.73638 4.59663 5.33333 5.33301 5.33333C6.06939 5.33333 6.66634 4.73638 6.66634 4C6.66634 3.26362 6.06939 2.66667 5.33301 2.66667ZM1.33301 4C1.33301 1.79086 3.12387 0 5.33301 0C7.54215 0 9.33301 1.79086 9.33301 4C9.33301 6.20914 7.54215 8 5.33301 8C3.12387 8 1.33301 6.20914 1.33301 4Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11.9999C3.64638 11.9999 3.30724 12.1404 3.05719 12.3904C2.80714 12.6405 2.66667 12.9796 2.66667 13.3333V30.6666C2.66667 31.403 2.06971 31.9999 1.33333 31.9999C0.596954 31.9999 0 31.403 0 30.6666V13.3333C0 12.2724 0.421428 11.255 1.17157 10.5048C1.92172 9.75468 2.93913 9.33325 4 9.33325H20C20.7364 9.33325 21.3333 9.93021 21.3333 10.6666C21.3333 11.403 20.7364 11.9999 20 11.9999H4Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.00008C12 3.2637 12.597 2.66675 13.3333 2.66675H30.6667C31.403 2.66675 32 3.2637 32 4.00008V21.3334C32 22.0698 31.403 22.6667 30.6667 22.6667H14.6667C13.9303 22.6667 13.3333 22.0698 13.3333 21.3334C13.3333 20.597 13.9303 20.0001 14.6667 20.0001H29.3333V5.33341H13.3333C12.597 5.33341 12 4.73646 12 4.00008Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1415 20.1079C22.8183 19.8178 23.6021 20.1314 23.8922 20.8082L27.8922 30.1415C28.1823 30.8184 27.8687 31.6022 27.1919 31.8923C26.5151 32.1824 25.7312 31.8688 25.4412 31.192L21.4412 21.8587C21.1511 21.1818 21.4646 20.398 22.1415 20.1079Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

TrainingIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
