export const Values = (
  <div className="core-values">
    <p>
      LECLE is a global software house headquartered in South Korea. Founded in
      2014, we received an initial investment from The Ventures and went through
      Plug and Play&apos;s accelerating program. Since then, we have offered
      innovative solutions that are best suited to various customers in Korea.
      In August 2017, we officially expanded our business to Vietnam with R&D
      Center in HCMC.
    </p>
    <p>
      Since 2018, we have decided to shift our focus onto blockchain business
      while continuing to develop our outsourcing projects. At present, we are
      preparing a new blockchain network based EOS.
    </p>
    <p>
      At Lecle, our people are always at the heart of the company. We are
      striving to make a desirable working environment where our members could
      accelerate their careers and perform at their best in satisfaction and
      happiness.
    </p>
  </div>
);
