import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const PlaneIcon = ({ color = '#EEF0F2', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5021 3.35022C9.73764 2.92824 10.1831 2.66675 10.6663 2.66675H17.333C17.7956 2.66675 18.2251 2.9065 18.4679 3.30023L23.4012 11.3002C23.7878 11.927 23.593 12.7485 22.9662 13.135C22.3394 13.5215 21.518 13.3267 21.1314 12.6999L16.5888 5.33341H13.055L16.7346 11.3002C17.1211 11.927 16.9263 12.7485 16.2995 13.135C15.6727 13.5215 14.8513 13.3267 14.4648 12.6999L9.53145 4.69993C9.27779 4.28859 9.26656 3.7722 9.5021 3.35022Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.6263 7.16578C1.87936 6.85031 2.26193 6.66675 2.66635 6.66675H6.66635C7.11216 6.66675 7.52847 6.88955 7.77575 7.26048L10.0466 10.6667H26.6664C29.6694 10.6667 31.9997 12.997 31.9997 16.0001C31.9997 19.0031 29.6694 21.3334 26.6664 21.3334H23.3799L18.4424 28.7397C18.1951 29.1106 17.7788 29.3334 17.333 29.3334H10.6664C10.1746 29.3334 9.72281 29.0628 9.49079 28.6292C9.25876 28.1957 9.28419 27.6696 9.55695 27.2605L13.5083 21.3334H5.33302C4.70809 21.3334 4.167 20.8994 4.03144 20.2893L1.36477 8.28932C1.27704 7.89453 1.37324 7.48125 1.6263 7.16578ZM4.32851 9.33341L6.40258 18.6667H15.9997C16.4914 18.6667 16.9432 18.9374 17.1753 19.3709C17.4073 19.8045 17.3818 20.3305 17.1091 20.7397L13.1577 26.6667H16.6194L21.557 19.2605C21.8042 18.8895 22.2206 18.6667 22.6664 18.6667H26.6664C28.1966 18.6667 29.333 17.5304 29.333 16.0001C29.333 14.4698 28.1966 13.3334 26.6664 13.3334H9.33302C8.88722 13.3334 8.47091 13.1106 8.22362 12.7397L5.95278 9.33341H4.32851Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);

PlaneIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
