import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
// import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import { JobPreview } from '../../components/JobPreview';
import { H3 } from '../../components/Text/H3';
import { P1 } from '../../components/Text/P1';
import { media } from '../../components/Theme/Media';
import { getJobList } from '../../services';
import { isDesktopScreen } from '../../utils/constants';
import { handleHoverCursor } from '../../utils/app';

const OpeningPositionsWrapper = styled.section`
  padding: 16rem 0;
  max-width: 140rem;
  margin: 0 auto;

  ${media.desktopGiant`
    max-width: 100rem;
  `};

  .opening-position__title {
    margin: 0 auto;
    max-width: 76.8rem;
    margin-bottom: 5rem;

    h3 {
      margin-bottom: 2.1rem;
      text-align: center;
      font-size: 4.8rem;
      line-height: 148%;
      letter-spacing: 0.04em;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.4rem;
      letter-spacing: 0.04em;
    }
  }

  .opening-position__list {
    padding-left: 12rem;
    display: grid;
    column-gap: 9rem;
    grid-template-columns: 1fr 1fr;
  }

  ${media.mobileWide`
    padding: 5rem 1.6rem 6rem;
    .opening-position__title {
      margin-bottom: 5rem;

      h3 {
        font-size: 3.2rem;
        line-height: 148%;
      }
    }
    .opening-position__list {
      padding-left: 0;
      grid-template-columns: 1fr;
      grid-row-gap: 6rem;
    }
  `}
`;

export const OpeningPositions = () => {
  const [openingJobs, setOpeningJobs] = useState({});

  useEffect(() => {
    getJobList().then(data => {
      const jobs = groupBy(
        data
          .filter(i => i.department_id && i.state === 'recruit')
          .map(i => ({ ...i, department: i.department_id[1] })),
        'department',
      );
      setOpeningJobs(jobs);
    });
    return () => handleHoverCursor(false);
  }, []);

  useEffect(() => {
    if (openingJobs) {
      if (isDesktopScreen) {
        window.scroll.update();
      }
      handleHoverCursor(true);
    }
  }, [openingJobs]);

  return (
    <OpeningPositionsWrapper>
      <div className="opening-position__title">
        <H3 data-scroll-speed="1" data-scroll>
          Open Positions
        </H3>
        <P1 data-scroll-speed="1" data-scroll>
          We are looking for passionate team members who have an insight for
          rapidly growing IT environment. Take a look and contact us.
        </P1>
      </div>

      <div className="opening-position__list">
        {Object.keys(openingJobs).map(title => (
          <JobPreview key={title} jobs={openingJobs[title]} title={title} />
        ))}
      </div>
    </OpeningPositionsWrapper>
  );
};

OpeningPositions.propTypes = {};
