import styled from '@emotion/styled';
import { bottomUpAuto } from '../../components/Theme/Animation';
import { media, sizes } from '../../components/Theme/Media';

export const Welcome = styled.section`
  text-align: center;
  padding: 21.2rem 0 0;
  font-size: 0;
  animation: bottomUpAuto 1.5s cubic-bezier(0.75, 0.58, 0.05, 0.86);

  h3 {
    max-width: 114.5rem;
    margin: 0 auto;
    font-size: 4.8rem;
    line-height: 5.7rem;
    letter-spacing: 0.04em;
  }

  img {
    width: 100%;
  }

  @media (min-width: ${sizes.desktopGiant}) {
    padding: 30rem 0 0;
  }

  ${media.mobileWide`
    margin-bottom: 0rem;
    h3 {
      margin-bottom: 6rem;
      font-size: 4rem;
      line-height: 4.8rem;
      letter-spacing: 0.04em;
    }

    .gatsby-image-wrapper {
        top: 0;
      }

  `};
  /* animation */
  ${bottomUpAuto}
`;
