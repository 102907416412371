import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const LaptopIcon = ({ color = '#EEF0F2', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66699 5.33341C6.31337 5.33341 5.97423 5.47389 5.72418 5.72394C5.47413 5.97399 5.33366 6.31313 5.33366 6.66675V17.3334C5.33366 18.0698 4.73671 18.6667 4.00033 18.6667C3.26395 18.6667 2.66699 18.0698 2.66699 17.3334V6.66675C2.66699 5.60588 3.08842 4.58847 3.83856 3.83832C4.58871 3.08818 5.60613 2.66675 6.66699 2.66675H25.3337C26.3945 2.66675 27.4119 3.08818 28.1621 3.83832C28.9122 4.58847 29.3337 5.60588 29.3337 6.66675V17.3334C29.3337 18.0698 28.7367 18.6667 28.0003 18.6667C27.2639 18.6667 26.667 18.0698 26.667 17.3334V6.66675C26.667 6.31313 26.5265 5.97399 26.2765 5.72394C26.0264 5.47389 25.6873 5.33341 25.3337 5.33341H6.66699Z"
        fill={color}
      />
      <path
        d="M16.0003 10.6667C16.7367 10.6667 17.3337 10.0697 17.3337 9.33333C17.3337 8.59695 16.7367 8 16.0003 8C15.2639 8 14.667 8.59695 14.667 9.33333C14.667 10.0697 15.2639 10.6667 16.0003 10.6667Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.390524 21.724C0.640573 21.474 0.979711 21.3335 1.33333 21.3335H30.6667C31.403 21.3335 32 21.9304 32 22.6668C32 24.4349 31.2976 26.1306 30.0474 27.3809C28.7971 28.6311 27.1014 29.3335 25.3333 29.3335H6.66667C4.89856 29.3335 3.20286 28.6311 1.95262 27.3809C0.702379 26.1306 0 24.4349 0 22.6668C0 22.3132 0.140476 21.9741 0.390524 21.724ZM2.89543 24.0002C3.09244 24.5574 3.41237 25.0694 3.83824 25.4953C4.58839 26.2454 5.6058 26.6668 6.66667 26.6668H25.3333C26.3942 26.6668 27.4116 26.2454 28.1618 25.4953C28.5876 25.0694 28.9076 24.5574 29.1046 24.0002H2.89543Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

LaptopIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
