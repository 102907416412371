import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const SalaryIcon = ({ color = '#EEF0F2', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M16 2.66667C8.6362 2.66667 2.66667 8.6362 2.66667 16C2.66667 23.3638 8.6362 29.3333 16 29.3333C23.3638 29.3333 29.3333 23.3638 29.3333 16C29.3333 8.6362 23.3638 2.66667 16 2.66667ZM0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.0003 5.33325C16.7367 5.33325 17.3337 5.93021 17.3337 6.66659V25.3333C17.3337 26.0696 16.7367 26.6666 16.0003 26.6666C15.2639 26.6666 14.667 26.0696 14.667 25.3333V6.66659C14.667 5.93021 15.2639 5.33325 16.0003 5.33325Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.1096 7.32408C17.2991 7.16717 19.8045 7.72157 21.1423 8.45688C21.7876 8.81159 22.0232 9.62227 21.6685 10.2676C21.3138 10.9129 20.5031 11.1485 19.8578 10.7938C19.0342 10.3411 17.055 9.85817 15.3002 9.98393C14.4339 10.046 13.7896 10.2467 13.3965 10.5239C13.0749 10.7505 12.864 11.0579 12.864 11.6427C12.864 12.3778 13.1462 12.8127 13.67 13.1968C14.284 13.6471 15.1602 13.9682 16.2749 14.3397C16.353 14.3657 16.4325 14.3921 16.5134 14.4189C17.4822 14.7397 18.638 15.1224 19.5474 15.7085C20.6272 16.4043 21.5094 17.4823 21.5094 19.1347C21.5094 20.5314 21.0419 21.6898 20.1533 22.5299C19.3001 23.3365 18.1835 23.7317 17.0781 23.8974C14.8874 24.2259 12.2738 23.7254 10.2141 22.9822C9.52146 22.7322 9.16258 21.9681 9.41253 21.2754C9.66249 20.5828 10.4266 20.2239 11.1193 20.4738C12.9503 21.1346 15.0914 21.4988 16.6827 21.2602C17.4682 21.1425 17.9995 20.8963 18.3214 20.592C18.6079 20.3212 18.8427 19.8992 18.8427 19.1347C18.8427 18.6257 18.6442 18.2989 18.103 17.9501C17.49 17.5551 16.6434 17.2731 15.5537 16.9102C15.5134 16.8968 15.4728 16.8833 15.4318 16.8696C14.3852 16.5208 13.1 16.0856 12.0931 15.3473C10.9959 14.5428 10.1974 13.3569 10.1974 11.6427C10.1974 10.1815 10.8375 9.06517 11.86 8.34433C12.8109 7.67394 14.0037 7.40333 15.1096 7.32408Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  </IconWrapper>
);

SalaryIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
