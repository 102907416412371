import { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import c1 from '../../../static/images/cultures/DSC_0037.png';
import c2 from '../../../static/images/cultures/DSC_0126.png';
import c3 from '../../../static/images/cultures/DSC_0130.png';
import c4 from '../../../static/images/cultures/DSC_0132.png';
import c5 from '../../../static/images/cultures/IMG_6460.png';
import c6 from '../../../static/images/cultures/IMG_6583.png';
import c7 from '../../../static/images/cultures/IMG_6653.png';
import c8 from '../../../static/images/cultures/IMG_6679.png';
import c9 from '../../../static/images/cultures/IMG_6683.png';
import c10 from '../../../static/images/cultures/IMG_6686.png';
import c11 from '../../../static/images/cultures/IMG_6730.png';
import c12 from '../../../static/images/cultures/IMG_6808.png';
import c13 from '../../../static/images/cultures/IMG_6864.png';
import { toLeftAuto } from '../../components/Theme/Animation';
import { colors } from '../../components/Theme/Colors';
import { media, sizes } from '../../components/Theme/Media';
import { Text } from '../../components/Theme/Mixins';
import { isSafari } from 'react-device-detect';

const SliderWrapper = styled.aside`
  position: relative;
  animation: toLeftAuto 1s ease-in-out;
  margin-top: 10.1rem;

  .slick-slide {
    padding: 0 1.5rem;
  }

  .slick-slide {
    cursor: grab;
  }

  .slick-arrow {
    bottom: -4.5rem;
    top: unset;
    line-height: 2.2rem;
    color: ${colors.white};
    font-size: 1.6rem;
    font-family: Ubuntu, sans-serif;
    font-weight: 500;
    letter-spacing: 0.04rem;
    width: auto;
    height: auto;
    transform: translateY(0%);
    ${!isSafari && Text} /* Safari does not support -webkit-background-clip: text; for <button> elements. */


    &.slick-prev {
      left: 30rem;
    }

    &.slick-next {
      right: unset;
      left: 40rem;
    }
  }

  .slick-prev:before {
    content: '';
  }

  .slick-next:before {
    content: '';
  }

  ${media.desktopGiant`
    height: 75vh;
    margin-top: 3rem;

    .slick-slide {
      padding: 0 0.5rem;
    }

    img {
      width: 95rem !important;
      max-height: 54rem;
      margin: 0 0.925rem 0 0;
      max-height: calc(75vh - 22rem);
      /* height: 100%; */
      max-width: 95rem !important;
      width: auto !important;
    }
  `}

  ${media.mobileWide`
    img {
      max-width: 28rem !important;
      max-height: 20.4rem;
      width: auto;
      height: auto;
    }
  `}
  ${toLeftAuto}
`;

const SlideNav = styled.div`
  width: fit-content;
  margin: 2.5rem 8.5rem 0 auto;

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 0.4rem;
    background-color: ${colors.eerie800};
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.1rem;
    height: 0.4rem;
    background-color: ${colors.white};
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 1.1rem;
    height: 0.4rem;
    background-color: ${colors.white};
    cursor: pointer;
  }

  .slider::-ms-range-thumb {
    width: 1.1rem;
    height: 0.4rem;
    background-color: ${colors.white};
    cursor: pointer;
  }

  ${media.mobileWide`
    margin: 2.5rem 3rem 0 auto;
  `}
`;

const images = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];

export const CultureSlider = ({ display }) => {
  const [slider, setSlider] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [count, setCount] = useState(0);

  const settings = {
    arrows: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    variableWidth: false,
    swipeToSlide: true,
    waitForAnimate: false,
    touchThreshold: 950,
    centerMode: true,
    centerPadding: '20%',
    adaptiveHeight: true,
    afterChange: () => setCount(count + 1),
    beforeChange: (currentSlide, nextSlide) => setSlideIndex(nextSlide),
    responsive: [
      {
        breakpoint: sizes.desktopGiant,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          adaptiveHeight: true,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: sizes.mobileWide,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          adaptiveHeight: false,
          centerPadding: '0px',
          arrows: false,
        },
      },
    ],
  };

  return (
    <SliderWrapper style={{ display: display ? 'block' : 'none' }} data-scroll>
      <Slider
        ref={slider => setSlider(slider)}
        className="hoverable"
        {...settings}
      >
        {images.map((i, idx) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            key={idx}
            alt="culture"
            draggable="false"
            src={i}
            onMouseDown={event => (event.target.style.cursor = 'grabbing')}
            onMouseUp={event => (event.target.style.cursor = 'grab')}
          />
        ))}
      </Slider>

      <SlideNav>
        <input
          className="slider"
          max={12}
          min={0}
          type="range"
          value={slideIndex}
          onChange={e => slider.slickGoTo(e.target.value)}
        />
      </SlideNav>
    </SliderWrapper>
  );
};

CultureSlider.propTypes = {
  display: PropTypes.any,
};
