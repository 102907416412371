import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const ArrowRight = ({ color = '#141414', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="24"
      height="11"
      viewBox="0 0 24 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.475 4.61259L20.6625 1.31259C20.25 0.900088 19.65 0.825088 19.275 1.20009C18.8625 1.61259 18.7875 2.21259 19.1625 2.58759L21.15 4.98759H1.0125C0.4125 4.98759 0 5.40009 0 6.00009C0 6.60009 0.4125 7.01259 1.0125 7.01259H21.1875L19.2 9.41259C18.7875 9.82509 18.9 10.5001 19.3125 10.8001C19.5 10.9876 19.725 10.9876 19.9125 10.9876C20.2125 10.9876 20.5125 10.8751 20.7 10.5751L23.5125 7.27509C24.1875 6.63759 24.1875 5.43759 23.475 4.61259Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

ArrowRight.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
