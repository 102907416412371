import styled from '@emotion/styled';
import { BenefitIcons } from '../../components/Icons/BenefitIcons';
import { media, sizes } from '../../components/Theme/Media';

const leftItems = [
  {
    text: 'Working hours: 9:00 - 18:00, Monday to Friday',
    icon: 'time',
  },
  {
    text: 'Macbook Pro provided',
    icon: 'laptop',
  },
  {
    text: '13th-month salary + KPI bonus at the end of the year',
    icon: 'salary',
  },
  { text: '15 days of annual leave per year', icon: 'plane' },
  { text: 'Performance review twice a year', icon: 'person' },
];

const rightItems = [
  {
    text: 'Regular training & sharing sessions',
    icon: 'training',
  },
  {
    text: 'Onsite opportunity in Korea',
    icon: 'onsite',
  },
  {
    text: `Events & activities: company trip at least once a year, monthly company
    parties & other teambuilding activities`,
    icon: 'event',
  },
  {
    text: 'Free coffee, snack and drinks',
    icon: 'coffee',
  },
];

const Wrapper = styled.div`
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.04em;

  .benefit-items {
    margin-bottom: 2.8rem;
    display: flex;
    align-items: center;
  }

  .benefit-icon {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 2.4rem;
  }

  ${media.desktopGiant`
    .benefit-items {
      margin-bottom: 2.8rem;
    }
  `}

  @media (min-width: ${sizes.desktopGiant}) {
    .benefit-items {
      margin-bottom: 5.6rem;
    }
  }

  ${media.mobileWide`
    display: flex;
    overflow-x: scroll;
    font-size: 1.6rem;
    line-height: 2.2rem;

    .benefit-items {
      margin-bottom: 3.2rem;
    }

    
    div {
      min-width: 25rem;
    }

    div:not(:last-child) {
      margin-right: 2rem;
    }

    .benefit-icon {
      width: 2rem;
      height: 2rem;
      margin-right: 1.6rem;
    }
  `}
`;

export const Benefits = (
  <Wrapper>
    <div>
      {leftItems.map(i => (
        <div key={i.text} className="benefit-items">
          <BenefitIcons className="benefit-icon" loading="lazy" name={i.icon} />
          <span>{i.text}</span>
        </div>
      ))}
    </div>
    <div>
      {rightItems.map(i => (
        <div key={i.text} className="benefit-items">
          <BenefitIcons className="benefit-icon" loading="lazy" name={i.icon} />
          <span>{i.text}</span>
        </div>
      ))}
    </div>
  </Wrapper>
);
